import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _41b82cd2 = () => interopDefault(import('../pages/dynamic/index.vue' /* webpackChunkName: "pages/dynamic/index" */))
const _6b008b2e = () => interopDefault(import('../pages/goods/index.vue' /* webpackChunkName: "pages/goods/index" */))
const _8e4fb5ac = () => interopDefault(import('../pages/live/index.vue' /* webpackChunkName: "pages/live/index" */))
const _f90c9104 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _2289eb5e = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _1d7737fe = () => interopDefault(import('../pages/personal/index.vue' /* webpackChunkName: "pages/personal/index" */))
const _fd2f91ea = () => interopDefault(import('../pages/relic/index.vue' /* webpackChunkName: "pages/relic/index" */))
const _3cbcbbb4 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _edfbf6f2 = () => interopDefault(import('../pages/seckill/index.vue' /* webpackChunkName: "pages/seckill/index" */))
const _386a966a = () => interopDefault(import('../pages/slice/index.vue' /* webpackChunkName: "pages/slice/index" */))
const _42c250ba = () => interopDefault(import('../pages/video-news/index.vue' /* webpackChunkName: "pages/video-news/index" */))
const _1acc89a6 = () => interopDefault(import('../pages/dynamic/play-video.vue' /* webpackChunkName: "pages/dynamic/play-video" */))
const _a5f8a8f4 = () => interopDefault(import('../pages/mall/auction/index.vue' /* webpackChunkName: "pages/mall/auction/index" */))
const _7c30b4a0 = () => interopDefault(import('../pages/slice/error.vue' /* webpackChunkName: "pages/slice/error" */))
const _5f4d25c4 = () => interopDefault(import('../pages/slice/information.vue' /* webpackChunkName: "pages/slice/information" */))
const _526f068f = () => interopDefault(import('../pages/slice/information/comments.vue' /* webpackChunkName: "pages/slice/information/comments" */))
const _520cef2c = () => interopDefault(import('../pages/slice/information/detail.vue' /* webpackChunkName: "pages/slice/information/detail" */))
const _456a9ed2 = () => interopDefault(import('../pages/slice/information/list-news.vue' /* webpackChunkName: "pages/slice/information/list-news" */))
const _dcd65dcc = () => interopDefault(import('../pages/slice/information/news-content.vue' /* webpackChunkName: "pages/slice/information/news-content" */))
const _cfb50458 = () => interopDefault(import('../pages/slice/information/timeline-news.vue' /* webpackChunkName: "pages/slice/information/timeline-news" */))
const _e747d4ca = () => interopDefault(import('../pages/slice/information/type-bar.vue' /* webpackChunkName: "pages/slice/information/type-bar" */))
const _3bad7b02 = () => interopDefault(import('../pages/slice/cloud-data/cultural-relic-share.vue' /* webpackChunkName: "pages/slice/cloud-data/cultural-relic-share" */))
const _83e9082c = () => interopDefault(import('../pages/slice/cloud-data/page-cultural-relic-list.vue' /* webpackChunkName: "pages/slice/cloud-data/page-cultural-relic-list" */))
const _f8693edc = () => interopDefault(import('../pages/slice/cloud-data/page-reference.vue' /* webpackChunkName: "pages/slice/cloud-data/page-reference" */))
const _8d938782 = () => interopDefault(import('../pages/slice/cloud-data/play-bar.vue' /* webpackChunkName: "pages/slice/cloud-data/play-bar" */))
const _672933a2 = () => interopDefault(import('../pages/slice/components/bottom-bar.vue' /* webpackChunkName: "pages/slice/components/bottom-bar" */))
const _4cee31ff = () => interopDefault(import('../pages/slice/components/personal-top-bar.vue' /* webpackChunkName: "pages/slice/components/personal-top-bar" */))
const _ab29318c = () => interopDefault(import('../pages/slice/components/top-bar.vue' /* webpackChunkName: "pages/slice/components/top-bar" */))
const _ec4155ec = () => interopDefault(import('../pages/slice/home/cloud-data.vue' /* webpackChunkName: "pages/slice/home/cloud-data" */))
const _6b122aa2 = () => interopDefault(import('../pages/slice/home/cloud-exhibition.vue' /* webpackChunkName: "pages/slice/home/cloud-exhibition" */))
const _3cf33ee7 = () => interopDefault(import('../pages/slice/home/cultural-mall.vue' /* webpackChunkName: "pages/slice/home/cultural-mall" */))
const _e8d567b4 = () => interopDefault(import('../pages/slice/home/cultural-news.vue' /* webpackChunkName: "pages/slice/home/cultural-news" */))
const _28ba43e2 = () => interopDefault(import('../pages/slice/home/focus-news-group.vue' /* webpackChunkName: "pages/slice/home/focus-news-group" */))
const _79440e98 = () => interopDefault(import('../pages/slice/home/live.vue' /* webpackChunkName: "pages/slice/home/live" */))
const _eb9f7776 = () => interopDefault(import('../pages/slice/home/page-home.vue' /* webpackChunkName: "pages/slice/home/page-home" */))
const _e815cee6 = () => interopDefault(import('../pages/slice/home/seckill-auction.vue' /* webpackChunkName: "pages/slice/home/seckill-auction" */))
const _4687ac2d = () => interopDefault(import('../pages/slice/home/video-news.vue' /* webpackChunkName: "pages/slice/home/video-news" */))
const _f1f2a022 = () => interopDefault(import('../pages/slice/live/page-live-detail.vue' /* webpackChunkName: "pages/slice/live/page-live-detail" */))
const _0e8c033c = () => interopDefault(import('../pages/slice/live/page-live-list.vue' /* webpackChunkName: "pages/slice/live/page-live-list" */))
const _439c5615 = () => interopDefault(import('../pages/slice/login-register/page-login-register.vue' /* webpackChunkName: "pages/slice/login-register/page-login-register" */))
const _dab0c992 = () => interopDefault(import('../pages/slice/personal/page-order-detail.vue' /* webpackChunkName: "pages/slice/personal/page-order-detail" */))
const _2c96a9fd = () => interopDefault(import('../pages/slice/personal/page-order-detail-logistics.vue' /* webpackChunkName: "pages/slice/personal/page-order-detail-logistics" */))
const _23310147 = () => interopDefault(import('../pages/slice/personal/page-personal.vue' /* webpackChunkName: "pages/slice/personal/page-personal" */))
const _a413ae22 = () => interopDefault(import('../pages/slice/personal/per-business-settlement.vue' /* webpackChunkName: "pages/slice/personal/per-business-settlement" */))
const _18b0c233 = () => interopDefault(import('../pages/slice/personal/per-business-settlement-state.vue' /* webpackChunkName: "pages/slice/personal/per-business-settlement-state" */))
const _013e477a = () => interopDefault(import('../pages/slice/personal/per-collect.vue' /* webpackChunkName: "pages/slice/personal/per-collect" */))
const _dae70b36 = () => interopDefault(import('../pages/slice/personal/per-info.vue' /* webpackChunkName: "pages/slice/personal/per-info" */))
const _60e2bca7 = () => interopDefault(import('../pages/slice/personal/per-order.vue' /* webpackChunkName: "pages/slice/personal/per-order" */))
const _6e236298 = () => interopDefault(import('../pages/slice/personal/per-order-list.vue' /* webpackChunkName: "pages/slice/personal/per-order-list" */))
const _c8fca57c = () => interopDefault(import('../pages/slice/personal/per-profile.vue' /* webpackChunkName: "pages/slice/personal/per-profile" */))
const _0a6d2e5c = () => interopDefault(import('../pages/slice/personal/per-receive-addr.vue' /* webpackChunkName: "pages/slice/personal/per-receive-addr" */))
const _cf5fe852 = () => interopDefault(import('../pages/slice/search/page-search.vue' /* webpackChunkName: "pages/slice/search/page-search" */))
const _5b104ae0 = () => interopDefault(import('../pages/slice/search/search-goods.vue' /* webpackChunkName: "pages/slice/search/search-goods" */))
const _2f40e5a2 = () => interopDefault(import('../pages/slice/search/search-live.vue' /* webpackChunkName: "pages/slice/search/search-live" */))
const _2de47409 = () => interopDefault(import('../pages/slice/search/search-news.vue' /* webpackChunkName: "pages/slice/search/search-news" */))
const _62f4cc5e = () => interopDefault(import('../pages/slice/seckill-auction-mall/page-auction-detail.vue' /* webpackChunkName: "pages/slice/seckill-auction-mall/page-auction-detail" */))
const _4cb0fa78 = () => interopDefault(import('../pages/slice/seckill-auction-mall/page-goods-list.vue' /* webpackChunkName: "pages/slice/seckill-auction-mall/page-goods-list" */))
const _3a549112 = () => interopDefault(import('../pages/slice/seckill-auction-mall/page-mall-detail.vue' /* webpackChunkName: "pages/slice/seckill-auction-mall/page-mall-detail" */))
const _26f5b7f2 = () => interopDefault(import('../pages/slice/seckill-auction-mall/page-seckill-detail.vue' /* webpackChunkName: "pages/slice/seckill-auction-mall/page-seckill-detail" */))
const _7185f0f1 = () => interopDefault(import('../pages/slice/seckill-auction-mall/payment-skip.vue' /* webpackChunkName: "pages/slice/seckill-auction-mall/payment-skip" */))
const _7f950ab2 = () => interopDefault(import('../pages/slice/video-news/video-news-detail.vue' /* webpackChunkName: "pages/slice/video-news/video-news-detail" */))
const _53de1bf4 = () => interopDefault(import('../pages/slice/video-news/video-news-list.vue' /* webpackChunkName: "pages/slice/video-news/video-news-list" */))
const _4e72d76e = () => interopDefault(import('../pages/mall/auction/_id.vue' /* webpackChunkName: "pages/mall/auction/_id" */))
const _1fd4c7a2 = () => interopDefault(import('../pages/relic/reference/_id.vue' /* webpackChunkName: "pages/relic/reference/_id" */))
const _0e053482 = () => interopDefault(import('../pages/dynamic/_id.vue' /* webpackChunkName: "pages/dynamic/_id" */))
const _2c18fdd4 = () => interopDefault(import('../pages/goods/_id.vue' /* webpackChunkName: "pages/goods/_id" */))
const _28bd2bdc = () => interopDefault(import('../pages/live/_id.vue' /* webpackChunkName: "pages/live/_id" */))
const _910d260e = () => interopDefault(import('../pages/news/_id.vue' /* webpackChunkName: "pages/news/_id" */))
const _c1c2ff9a = () => interopDefault(import('../pages/relic/_id.vue' /* webpackChunkName: "pages/relic/_id" */))
const _64857caf = () => interopDefault(import('../pages/seckill/_id.vue' /* webpackChunkName: "pages/seckill/_id" */))
const _6a3466cb = () => interopDefault(import('../pages/video-news/_id.vue' /* webpackChunkName: "pages/video-news/_id" */))
const _8c5bd732 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/dynamic",
    component: _41b82cd2,
    name: "dynamic"
  }, {
    path: "/goods",
    component: _6b008b2e,
    name: "goods"
  }, {
    path: "/live",
    component: _8e4fb5ac,
    name: "live"
  }, {
    path: "/login",
    component: _f90c9104,
    name: "login"
  }, {
    path: "/news",
    component: _2289eb5e,
    name: "news"
  }, {
    path: "/personal",
    component: _1d7737fe,
    name: "personal"
  }, {
    path: "/relic",
    component: _fd2f91ea,
    name: "relic"
  }, {
    path: "/search",
    component: _3cbcbbb4,
    name: "search"
  }, {
    path: "/seckill",
    component: _edfbf6f2,
    name: "seckill"
  }, {
    path: "/slice",
    component: _386a966a,
    name: "slice"
  }, {
    path: "/video-news",
    component: _42c250ba,
    name: "video-news"
  }, {
    path: "/dynamic/play-video",
    component: _1acc89a6,
    name: "dynamic-play-video"
  }, {
    path: "/mall/auction",
    component: _a5f8a8f4,
    name: "mall-auction"
  }, {
    path: "/slice/error",
    component: _7c30b4a0,
    name: "slice-error"
  }, {
    path: "/slice/information",
    component: _5f4d25c4,
    name: "slice-information",
    children: [{
      path: "comments",
      component: _526f068f,
      name: "slice-information-comments"
    }, {
      path: "detail",
      component: _520cef2c,
      name: "slice-information-detail"
    }, {
      path: "list-news",
      component: _456a9ed2,
      name: "slice-information-list-news"
    }, {
      path: "news-content",
      component: _dcd65dcc,
      name: "slice-information-news-content"
    }, {
      path: "timeline-news",
      component: _cfb50458,
      name: "slice-information-timeline-news"
    }, {
      path: "type-bar",
      component: _e747d4ca,
      name: "slice-information-type-bar"
    }]
  }, {
    path: "/slice/cloud-data/cultural-relic-share",
    component: _3bad7b02,
    name: "slice-cloud-data-cultural-relic-share"
  }, {
    path: "/slice/cloud-data/page-cultural-relic-list",
    component: _83e9082c,
    name: "slice-cloud-data-page-cultural-relic-list"
  }, {
    path: "/slice/cloud-data/page-reference",
    component: _f8693edc,
    name: "slice-cloud-data-page-reference"
  }, {
    path: "/slice/cloud-data/play-bar",
    component: _8d938782,
    name: "slice-cloud-data-play-bar"
  }, {
    path: "/slice/components/bottom-bar",
    component: _672933a2,
    name: "slice-components-bottom-bar"
  }, {
    path: "/slice/components/personal-top-bar",
    component: _4cee31ff,
    name: "slice-components-personal-top-bar"
  }, {
    path: "/slice/components/top-bar",
    component: _ab29318c,
    name: "slice-components-top-bar"
  }, {
    path: "/slice/home/cloud-data",
    component: _ec4155ec,
    name: "slice-home-cloud-data"
  }, {
    path: "/slice/home/cloud-exhibition",
    component: _6b122aa2,
    name: "slice-home-cloud-exhibition"
  }, {
    path: "/slice/home/cultural-mall",
    component: _3cf33ee7,
    name: "slice-home-cultural-mall"
  }, {
    path: "/slice/home/cultural-news",
    component: _e8d567b4,
    name: "slice-home-cultural-news"
  }, {
    path: "/slice/home/focus-news-group",
    component: _28ba43e2,
    name: "slice-home-focus-news-group"
  }, {
    path: "/slice/home/live",
    component: _79440e98,
    name: "slice-home-live"
  }, {
    path: "/slice/home/page-home",
    component: _eb9f7776,
    name: "slice-home-page-home"
  }, {
    path: "/slice/home/seckill-auction",
    component: _e815cee6,
    name: "slice-home-seckill-auction"
  }, {
    path: "/slice/home/video-news",
    component: _4687ac2d,
    name: "slice-home-video-news"
  }, {
    path: "/slice/live/page-live-detail",
    component: _f1f2a022,
    name: "slice-live-page-live-detail"
  }, {
    path: "/slice/live/page-live-list",
    component: _0e8c033c,
    name: "slice-live-page-live-list"
  }, {
    path: "/slice/login-register/page-login-register",
    component: _439c5615,
    name: "slice-login-register-page-login-register"
  }, {
    path: "/slice/personal/page-order-detail",
    component: _dab0c992,
    name: "slice-personal-page-order-detail"
  }, {
    path: "/slice/personal/page-order-detail-logistics",
    component: _2c96a9fd,
    name: "slice-personal-page-order-detail-logistics"
  }, {
    path: "/slice/personal/page-personal",
    component: _23310147,
    name: "slice-personal-page-personal"
  }, {
    path: "/slice/personal/per-business-settlement",
    component: _a413ae22,
    name: "slice-personal-per-business-settlement"
  }, {
    path: "/slice/personal/per-business-settlement-state",
    component: _18b0c233,
    name: "slice-personal-per-business-settlement-state"
  }, {
    path: "/slice/personal/per-collect",
    component: _013e477a,
    name: "slice-personal-per-collect"
  }, {
    path: "/slice/personal/per-info",
    component: _dae70b36,
    name: "slice-personal-per-info"
  }, {
    path: "/slice/personal/per-order",
    component: _60e2bca7,
    name: "slice-personal-per-order"
  }, {
    path: "/slice/personal/per-order-list",
    component: _6e236298,
    name: "slice-personal-per-order-list"
  }, {
    path: "/slice/personal/per-profile",
    component: _c8fca57c,
    name: "slice-personal-per-profile"
  }, {
    path: "/slice/personal/per-receive-addr",
    component: _0a6d2e5c,
    name: "slice-personal-per-receive-addr"
  }, {
    path: "/slice/search/page-search",
    component: _cf5fe852,
    name: "slice-search-page-search"
  }, {
    path: "/slice/search/search-goods",
    component: _5b104ae0,
    name: "slice-search-search-goods"
  }, {
    path: "/slice/search/search-live",
    component: _2f40e5a2,
    name: "slice-search-search-live"
  }, {
    path: "/slice/search/search-news",
    component: _2de47409,
    name: "slice-search-search-news"
  }, {
    path: "/slice/seckill-auction-mall/page-auction-detail",
    component: _62f4cc5e,
    name: "slice-seckill-auction-mall-page-auction-detail"
  }, {
    path: "/slice/seckill-auction-mall/page-goods-list",
    component: _4cb0fa78,
    name: "slice-seckill-auction-mall-page-goods-list"
  }, {
    path: "/slice/seckill-auction-mall/page-mall-detail",
    component: _3a549112,
    name: "slice-seckill-auction-mall-page-mall-detail"
  }, {
    path: "/slice/seckill-auction-mall/page-seckill-detail",
    component: _26f5b7f2,
    name: "slice-seckill-auction-mall-page-seckill-detail"
  }, {
    path: "/slice/seckill-auction-mall/payment-skip",
    component: _7185f0f1,
    name: "slice-seckill-auction-mall-payment-skip"
  }, {
    path: "/slice/video-news/video-news-detail",
    component: _7f950ab2,
    name: "slice-video-news-video-news-detail"
  }, {
    path: "/slice/video-news/video-news-list",
    component: _53de1bf4,
    name: "slice-video-news-video-news-list"
  }, {
    path: "/mall/auction/:id",
    component: _4e72d76e,
    name: "mall-auction-id"
  }, {
    path: "/relic/reference/:id?",
    component: _1fd4c7a2,
    name: "relic-reference-id"
  }, {
    path: "/dynamic/:id",
    component: _0e053482,
    name: "dynamic-id"
  }, {
    path: "/goods/:id",
    component: _2c18fdd4,
    name: "goods-id"
  }, {
    path: "/live/:id",
    component: _28bd2bdc,
    name: "live-id"
  }, {
    path: "/news/:id",
    component: _910d260e,
    name: "news-id"
  }, {
    path: "/relic/:id",
    component: _c1c2ff9a,
    name: "relic-id"
  }, {
    path: "/seckill/:id",
    component: _64857caf,
    name: "seckill-id"
  }, {
    path: "/video-news/:id?",
    component: _6a3466cb,
    name: "video-news-id"
  }, {
    path: "/",
    component: _8c5bd732,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
