import { Request } from '~/core/services/http/request'
import { User } from '~/constants/api/user'

class UserHttpInteractor {
  service

  constructor (service) {
    this.service = service
  }

  async info () {
    const options = {
      url: User.info
    }
    return await this.service.get(options)
  }

  async login (data) {
    const options = {
      url: User.login,
      data
    }
    return await this.service.post(options)
  }
}

export const userHttpInteractor = new UserHttpInteractor(Request.getInstance())
