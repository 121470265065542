import { Request } from '~/core/services/http/request'
import { Live, pageSize } from '~/constants/api/live'

class LiveHttpInteractor {
  service

  constructor (service) {
    this.service = service
  }

  async detail (params) {
    const options = {
      url: Live.getItem,
      params
    }
    return await this.service.get(options)
  }

  /**
   * 直播留言列表
   * @param params
   * @returns {Promise<*>}
   */
  async messageLists (params) {
    const options = {
      url: Live.messageLists,
      params
    }
    return await this.service.get(options)
  }

  /**
   * 发布直播留言
   * @param data
   * @returns {Promise<*>}
   */
  async pushMessage (data) {
    const options = {
      url: Live.pushMessage,
      data
    }
    return await this.service.post(options)
  }

  async pclist (params) {
    const options = {
      url: Live.pclist,
      params: Object.assign({}, params, { page_type: 'pc', n: pageSize })
    }
    return await this.service.get(options)
  }
}

export const liveHttpInteractor = new LiveHttpInteractor(Request.getInstance())
