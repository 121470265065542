export const Mall = {
  category: '/goods/category',
  detail: '/goods/_id_',
  collect: '/goods/collect',
  pclist: '/goods/pclist',
  // 竞拍流程\保证金须知\保障服务\竞拍协议
  configure: '/auction/configure',
  record: '/auction/record/_id_'
}

export const pageSize = 20
