export const state = () => ({
  config: []
})

export const mutations = {
  SET_CONFIG (state, config) {
    state.config = config
  }
}

export const actions = {
  getConfig ({ commit, state }) {
    // TODO
  }
}
