import Vue from 'vue'
if (process.client) {
  window.redirectToPage = function (obj) {
    console.log(obj)
  }
}
export default ({ app, store }) => {
  Vue.prototype.$store = store
  app.store.dispatch('user/getInfo')
  app.router.beforeEach((to, from, next) => {
    next()
  })
}
