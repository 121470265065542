import Vue from 'vue'
import Report from '@/utils/report'
// 错误处理
const NODE_ENV = process.env.NODE_ENV
const VUE_APP_SENTRY_ENABLED = process.env.VUE_APP_SENTRY_ENABLED
const VUE_APP_BASE_PATH = process.env.VUE_APP_BASE_PATH
const VUE_APP_SENTRY_DSN = process.env.VUE_APP_SENTRY_DSN
const PROD = NODE_ENV === 'production'

if (PROD && VUE_APP_SENTRY_ENABLED === 'yes') {
  const sentry = Report.getInstance(Vue, {
    dsn: VUE_APP_SENTRY_DSN,
    release: VUE_APP_BASE_PATH + '@' + __VERSION__,
    environment: 'Prod'
  })

  if (process.client) {
    window.$sentry = sentry
  }

  Vue.config.errorHandler = (error, vm, info) => {
    if (process.client) {
      window.$sentry.log({
        error,
        type: 'vue errorHandler',
        vm,
        info
      })
    }
  }
}
