import { Request } from '~/core/services/http/request'
import { Upload, UploadConfig } from '~/constants/api/upload'

class UploadHttpInteractor {
  service

  constructor (service) {
    this.service = service
  }

  /**
   * 文件上传操作,直接调用 axios 的 post 方法,然后修改 headers 的方式进行上传
   * @param file
   * @returns {Promise<*|undefined>}
   */
  async upload (file) {
    const formData = new FormData() // 构造一个 FormData，把后台需要发送的参数添加
    formData.append('file', file) // 接口需要传的参数
    Object.keys(UploadConfig).map(item => {
      formData.append(item, UploadConfig[item])
    })

    const optons = {
      headers: {
        'Content-Type': 'multipart/form-data;charset=utf-8'
      },
      url: Upload,
      data: formData
    }
    return await this.service.post(optons)
  }

  async getLists (params) {
    const options = {
      url: 'http://ccwbshop.ccwb.cn/api/Goods/getGoodsList',
      params
    }
    return await this.service.get(options)
  }
}

export const uploadHttpInteractor = new UploadHttpInteractor(Request.getInstance())
