import { Request } from '~/core/services/http/request'
import { News, pageSize } from '~/constants/api/news'

class NewsHttpInteractor {
  service

  constructor (service) {
    this.service = service
  }

  /**
   * 获取新闻分类
   * @param params
   * @returns {Promise<*>}
   */
  async newscate (params) {
    const options = {
      url: News.newscate,
      params
    }
    return await this.service.get(options)
  }

  /**
   * 获取新闻详情
   * @param id
   * @returns {Promise<*>}
   */
  async detail (id) {
    const options = {
      url: News.detail + '/' + id
    }
    return await this.service.get(options)
  }

  /**
   * 获取新闻列表
   * @param params
   * @returns {Promise<*>}
   */
  async news (params) {
    const options = {
      url: News.news,
      params: Object.assign({}, params, { page_type: 'pc', n: pageSize })
    }
    return await this.service.get(options)
  }

  /**
   * 获取时间轴新闻
   * @param params
   * @returns {Promise<*>}
   */
  async timeline (params) {
    const options = {
      url: News.timeline,
      params
    }
    return await this.service.get(options)
  }

  /**
   * 查询指定新闻的评论列表
   * @param id
   * @returns {Promise<*>}
   */
  async comment (id, params) {
    const options = {
      url: News.comment.replace('_id_', id),
      params: Object.assign({}, params, { page_type: 'pc', n: pageSize })
    }
    return await this.service.get(options)
  }

  async addComment (id, data) {
    const options = {
      url: News.comment.replace('_id_', id),
      data
    }
    return await this.service.post(options)
  }

  async delComment (id, data) {
    const options = {
      url: News.delcomment.replace('_id_', id),
      data
    }
    return await this.service.delete(options)
  }

  /**
   * 新闻点赞
   * @param id
   * @returns {Promise<*>}
   */
  async like (id) {
    const options = {
      url: News.like.replace('_id_', id)
    }
    return await this.service.post(options)
  }

  /**
   * 给新闻评论点赞
   * @param id
   * @returns {Promise<*>}
   */
  async likecomment (id) {
    const options = {
      url: News.likecomment.replace('_id_', id)
    }
    return await this.service.post(options)
  }
}

export const newsHttpInteractor = new NewsHttpInteractor(Request.getInstance())
