import { removeToken, getToken, setToken } from '~/core/services/cache'
import { userHttpInteractor } from '@/core'

export const state = () => ({
  token: getToken(),
  info: {}
})

export const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
    setToken(token)
  },
  REMOVE_TOKEN: (state, token) => {
    state.token = ''
    removeToken()
  },
  SET_INFO: (state, info) => {
    state.info = info
  }
}

export const actions = {
  async nuxtServerInit ({ commit, state }, { req }) {
    console.log('user nuxtServerInit')
    if (state.token || getToken()) {
      const { data, code } = await userHttpInteractor.info()
      if (code === 200) {
        commit('SET_INFO', data)
      }
    }
  },
  async getInfo ({ commit, state }) {
    if (state.token || getToken()) {
      const { data, code } = await userHttpInteractor.info()
      if (code === 200) {
        commit('SET_INFO', data)
      }
    }
  },

  logout ({ commit, state, dispatch }) {
    commit('SET_TOKEN', '')
    removeToken()
  },

  resetToken ({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      removeToken()
      resolve()
    })
  }
}

