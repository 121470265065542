import { commonHttpInteractor } from '~/core'
import { TOKEN_KEY } from '@/constants/settings'
import Cookies from 'cookie-parse'

export const state = () => ({
  initData: []
})

export const mutations = {
  SET_INIT_DATA (state, data) {
    state.initData = data
  }
}

export const actions = {
  async nuxtServerInit ({ commit }, { req }) {
    const { data } = await commonHttpInteractor.nav({ cid: 4 })
    commit('SET_INIT_DATA', data.data)
    if (req.headers.cookie) {
      const token = Cookies.parse(req.headers.cookie)[TOKEN_KEY]
      commit('user/SET_TOKEN', token)
    }
  }
}
