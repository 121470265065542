import { Request } from '~/core/services/http/request'
import { Common } from '~/constants/api/common'

class CommonHttpInteractor {
  service

  constructor (service) {
    this.service = service
  }

  async sendSmsCode (data) {
    const options = {
      url: Common.sendSmsCode,
      data
    }
    return await this.service.post(options)
  }

  async nav (params) {
    const options = {
      url: Common.nav,
      params
    }
    return await this.service.get(options)
  }

  async indexData (params) {
    const options = {
      url: Common.indexData,
      params
    }
    return await this.service.get(options)
  }
}

export const commonHttpInteractor = new CommonHttpInteractor(Request.getInstance())
