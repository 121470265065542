export const News = {
  newscate: '/newscate',
  detail: '/news',
  news: '/news',
  timeline: '/news/timeline',
  like: '/news/_id_/like',
  comment: '/news/_id_/comment',
  likecomment: '/news/likecomment/_id_',
  delcomment: '/news/comment/_id_'
}

export const pageSize = 15
