import { setLanguage } from '~/core/services/cache'
import { uploadHttpInteractor } from '~/core'

export const state = () => ({
  initData: []
})

export const mutations = {
  SET_INIT_DATA (state, language) {
    state.language = language
    setLanguage(language)
  }
}

export const actions = {
  async getInitData ({ commit }, payload) {
    const { data } = await uploadHttpInteractor.getLists({ goods_commend: 1, p: 1 })
    commit('SET_INIT_DATA', data)
  }
}
