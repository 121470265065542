<template>
  <!--404-->
  <div class="page-error">
    <img src="~assets/images/page-error.jpg" alt="img">
    <div class="content">
      <div class="tit">抱歉，出现错误了！</div>
      <div class="subtit">哎呀～稍后再试哦～</div>
      <nuxt-link to="/" class="btn-back">返回首页</nuxt-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageError',
  data () {
    return {}
  },
  created () {
  },
  methods: {}
}
</script>

<style scoped lang="scss">
.page-error {
  position: relative;
  img {
    display: block;
    max-width: 100%;
  }
  .content {
    position: absolute;
    top: 28%;
    right: 25%;
    color: #302c48;
    .tit {
      font-size: 48px;
    }
    .subtit {
      font-size: 20px;
      margin: 10px auto 30px;
    }
    .btn-back {
      width: 5em;
      font-size: 20px;
      font-weight: 500;
      color: #fff;
      background-color: #9997d4;
      border-radius: 30px;
      padding: 10px 20px;
      box-sizing: content-box;
      text-align: center;
      cursor: pointer;
    }
  }
}
</style>
