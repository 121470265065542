import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import { getToken, removeToken } from '../cache'
import Vue from 'vue'
// TODO 暂时没有找到太好的版本怎么在服务器端处理 store,官方也没有给出解决方案,操蛋
const store = Vue.prototype.$store

function errorReport (url, error, requestOptions, response) {
  if (process.browser && window.$sentry) {
    const errorInfo = {
      error: typeof error === 'string' ? new Error(error) : error,
      type: 'request',
      requestUrl: url,
      requestOptions: JSON.stringify(requestOptions)
    }

    if (response) {
      errorInfo.response = JSON.stringify(response)
    }

    window.$sentry.log(errorInfo)
  }
}

const DEFAULT_OPTIONS = {
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json;chareset=UTF-8'
  }
}

const responseLog = (response) => {
  if (process.browser && process.env.NODE_ENV === 'development') {
    const randomColor = `rgba(${Math.round(Math.random() * 255)},${Math.round(
      Math.random() * 255
    )},${Math.round(Math.random() * 255)})`
    console.log(
      '%c┍------------------------------------------------------------------┑',
      `color:${randomColor};`
    )
    console.log('| 请求地址：', response.config.url)
    console.log('| 请求参数：', !_.isEmpty(response.config.data) ? JSON.parse(response.config.data) : {})
    console.log('| 返回数据：', response.data)
    console.log(
      '%c┕------------------------------------------------------------------┙',
      `color:${randomColor};`
    )
  }
}

const instance = axios.create(DEFAULT_OPTIONS)

instance.interceptors.request.use(
  (config) => {
    let userToken = getToken()
    if (store && store.state && store.state.user.token) {
      userToken = store.state.user.token
    }
    if (userToken) {
      config.headers['Authorization'] = 'Bearer ' + userToken
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

// response interceptor
instance.interceptors.response.use(
  response => {
    // 打印日志
    responseLog(response)
    const res = response.data

    // if the custom code is not 20000, it is judged as an error.
    // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
    if (res.code === 401) {
      // to re-login
      MessageBox.confirm('您还没有登录,请先登录', '提示', {
        confirmButtonText: '去登录',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        removeToken()
        if (process.client) {
          window.location.href = '/login'
        }
      }).catch(r => {
        console.log(r)
      })
      // return Promise.reject(new Error(res.message || 'Error'))
    }
    if (res.code === 403) {
      MessageBox.confirm('您没有改操作权限,请联系管理员,或者可以点击重新登陆更换账号,', '权限不足', {
        confirmButtonText: '重新登陆',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
      })
      return Promise.reject(new Error(res.message || 'Error'))
    }
    return res
  },
  error => {
    if (error.response) {
      const { config: { data: requestOptions, url }, data: responseData, statusText } = error.response
      errorReport(url, statusText, requestOptions, responseData)
    }
    Message({
      message: '服务器繁忙,请稍后再试~~~~', // error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default async function (options) {
  const { url } = options
  const params = Object.assign({}, options.params, { client: 'pc' })
  const requestOptions = Object.assign({}, options, { params })
  try {
    return await instance.request(requestOptions)
  } catch (err) {
    errorReport(url, err, requestOptions)
    throw err
  }
}

export const AxiosInit = instance
