import { Request } from '~/core/services/http/request'
import { Mall, pageSize } from '~/constants/api/mall'

class MallHttpInteractor {
  service

  constructor (service) {
    this.service = service
  }

  async collect (data) {
    const options = {
      url: Mall.collect,
      data
    }
    return await this.service.post(options)
  }

  async detail (id) {
    const options = {
      url: Mall.detail.replace('_id_', id)
    }
    return await this.service.get(options)
  }

  /**
   * 获取产品出价记录
   * @param id
   * @returns {Promise<*>}
   */
  async record (id) {
    const options = {
      url: Mall.record.replace('_id_', id)
    }
    return await this.service.get(options)
  }

  async configure (params) {
    const options = {
      url: Mall.configure,
      params: Object.assign({}, { typename: 'auction_process,auction_margin_notice,auction_security_service,auction_protocol' }, params)
    }

    return await this.service.get(options)
  }

  async category (params) {
    const options = {
      url: Mall.category,
      params
    }
    return await this.service.get(options)
  }

  async pclist (params) {
    const options = {
      url: Mall.pclist,
      params: Object.assign({}, params, { page_type: 'pc', n: pageSize })
    }
    return await this.service.get(options)
  }
}

export const mallHttpInteractor = new MallHttpInteractor(Request.getInstance())
